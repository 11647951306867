import { logServiceClient } from "./api";
import { useAuthStore } from '@/store/authStore';

import StackTrace from 'stacktrace-js';

export default class LogService {


    static async info(message: string) {
        return await this.log(message, "info");
    }
    static async warn(message: string) {
        return await this.log(message, "warn");
    }
    static async error(message: string) {
        return await this.log(message, "error");
    }
    static async log(message: string, level = "info") {
        const authStore = useAuthStore();
        const userProfile = authStore.getUserProfile();

        const error = new Error('BOOM!');
        const stackTrace = await StackTrace.fromError(error);
        const payload = {
            message: message,
            level: level,
            user_id: userProfile.id,
            user_email: userProfile.email,
            user_name: userProfile.username,
            stack_trace: stackTrace,
            version: window.swVersion || 'unknown'
        };
        console.log("LogService", payload);

        const response = await logServiceClient.post('/logservice/log', payload);
        if (response.status !== 200) {
            console.error("Failed to log message", message);
            return false
        } else {
            console.log("Logged message", message);
            return true;
        }
    }

    /**
     * Retrieves logs for the current user with optional pagination.
     * 
     * @param limit - Maximum number of items to retrieve (optional, defaults to 10 on the backend)
     * @param lastEvaluatedKey - Pagination token from a previous response (optional)
     * @returns An object with:
     *           - logs: Array of log entries,
     *           - last_evaluated_key: Pagination token (if available),
     *           - total_count: Total number of log entries for the user.
    */
    static async get(user_id: string, limit?: number, lastEvaluatedKey?: string) {
        const authStore = useAuthStore();

        const params: any = {
            user_id: user_id
        };



        if (limit) {
            params.limit = limit;
        }

        if (lastEvaluatedKey) {
            params.last_evaluated_key = lastEvaluatedKey;
        }

        console.log("Fetching logs with params", params);
        const response = await logServiceClient.get('/logservice/get', { params });
        console.log("Get logs response", response);
        return response.data;
    }
}