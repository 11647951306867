import axios from 'axios';
import route from '@/router';
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { useAuthStore } from '@/store/authStore';
import { Response } from '@/models/Response';
import { Challenge } from '@/models/Challenge';
import { Judge } from '@/models/Judge';
import { UserProfile, UserProfileData } from '@/models/UserProfile';
import { FetchNotificationsApiResponse } from '@/interfaces';
import { setupInterceptors } from './axiosInterceptor';

// TODO parameterize 'dev' as an environment variable
const USER_SERVICE_URL = process.env.VUE_APP_USERSERVICE_BASE_URL || 'https://dev-userservice-us-east-1.duelly.io';
const NOTIFY_URL = process.env.VUE_APP_NOTIFYSERVICE_BASE_URL || 'https://dev-notificationservice-us-east-1.duelly.io';
const CHALLENGE_URL = process.env.VUE_APP_CHALLENGESERVICE_BASE_URL || 'https://dev-challangeservice-us-east-1.duelly.io';
const ADMIN_SERVICE_URL = process.env.VUE_APP_ADMIN_SERVICE_BASE_URL || 'https://dev-admin-service-us-east-1.duelly.io';
const LOG_URL = process.env.VUE_APP_LOGSERVICE_BASE_URL || 'https://dev-logservice-us-east-1.duelly.io';

const userServiceClient = axios.create({
    baseURL: USER_SERVICE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const notificationServiceClient = axios.create({
    baseURL: NOTIFY_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const challengeServiceClient = axios.create({
    baseURL: CHALLENGE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const adminServiceClient = axios.create({
    baseURL: ADMIN_SERVICE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const logServiceClient = axios.create({
    baseURL: LOG_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export { userServiceClient, notificationServiceClient, challengeServiceClient, logServiceClient, adminServiceClient };

const clients = [userServiceClient, notificationServiceClient, challengeServiceClient, logServiceClient, adminServiceClient];

clients.forEach(client => {
    setupInterceptors(client);
});


export default {

    log(message: string, level: string, user_id: string, user_email: string, user_name: string, user_role: string) {

        return logServiceClient.post('/front-end-logs', {
            message,
            level,
            user_id,
            user_email,
            user_name,
            user_role
        });
    },

    like(access_token: string, parent_type: string, parent_id: string) {
        return challengeServiceClient.post('/challenge/like', {
            parent_type,
            parent_id,
        }, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            }
        });
    },
    unlike(access_token: string, parent_type: string, parent_id: string) {
        return challengeServiceClient.post('/challenge/unlike', {
            parent_type,
            parent_id,
        }, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            }
        });
    },

    createChallenge(access_token: string, type: string, videoType: string, parent_id: string, numParts = 1) {
        return challengeServiceClient.post(
            `/challenge/create`,
            {
                type,
                numParts,
                videoType,
                parent_id
            },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
    },

    createResponse(access_token: string, type: string, videoType: string, parent_id: string, challengeUserId: string, numParts = 1) {
        return challengeServiceClient.post(
            `/challenge/create-response`,
            {
                type,
                numParts,
                videoType,
                challenge_user_id: challengeUserId,
                challenge_id: parent_id,
            },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
    },

    createPrizeVideo(
        access_token: string,
        videoType: string,
        parent_id: string,
        prize_type = "competitor-prize",
        numParts = 1
    ) {
        return challengeServiceClient.post(
            `/challenge/create-prize-video`,
            {
                numParts,
                videoType,
                challenge_id: parent_id,
                prize_type,
            },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
    },

    getResponseById(response_id: string) {
        return challengeServiceClient.get(
            `/challenge/get-response`, {
            params: {
                response_id,
            }
        });
    },

    fetchJudgesByUserId(access_token: string, user_id: string, parent_ids: string[]) {
        return challengeServiceClient.get(
            `/challenge/fetch-judges-by-user-id`, {
            params: {
                user_id,
                parent_ids,
            },
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            }
        }
        );
    },

    getJudgedResponse(access_token: string, user_id: string, response_id: string[]) {
        return challengeServiceClient.get(
            `/challenge/get-judged-response`, {
            params: {
                user_id,
                response_id,
            },
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            }
        }
        );
    },

    async getUserChallengeResponsesCount(challenge_id: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/get-challenge-responses-count', {
            params: {
                "challenge_id": challenge_id,
            }
        });
        return resp.data.count ? resp.data.count : 0;
    },

    async getJudgeCount(challenge_id: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/get-judge-count', {
            params: {
                "challenge_id": challenge_id,
            }
        });
        return resp.data.count ? resp.data.count : 0;
    },

    async getUserChallenges(user_id: string, limit = 10, last_evaluated_key: any = null): Promise<{ challenges: Challenge[], lastEvaluatedKey: any }> {
        const response = await challengeServiceClient.get('/challenge/user-challenges', {
            params: {
                user_id,
                limit,
                last_evaluated_key: last_evaluated_key ? JSON.stringify(last_evaluated_key) : null,
            }
        });
        if (!response.data.challenges || response.data.challenges.length === 0) {
            return { challenges: [], lastEvaluatedKey: null };
        }
        return {
            challenges: response.data.challenges,
            lastEvaluatedKey: response.data.last_evaluated_key || null,
        };
    },

    async getUserJudgedChallenges(accessToken: string, user_id: string, limit = 10, last_evaluated_key: any = null):
        Promise<{ challenges: Challenge[], judges: Judge[], lastEvaluatedKey: any }> {
        const response = await challengeServiceClient.get('/challenge/user-judged-challenges', {
            params: {
                user_id,
                limit,
                last_evaluated_key: last_evaluated_key ? JSON.stringify(last_evaluated_key) : null,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        if (!response.data.challenges || response.data.challenges.length === 0) {
            return { challenges: [], judges: [], lastEvaluatedKey: null };
        }
        return {
            judges: response.data.judges,
            challenges: response.data.challenges,
            lastEvaluatedKey: response.data.last_evaluated_key || null,
        };
    },

    async getUserResponses(user_id: string, limit = 10, last_evaluated_key: any = null): Promise<{ responses: Response[], lastEvaluatedKey: any }> {
        const response = await challengeServiceClient.get('/challenge/user-responses', {
            params: {
                user_id,
                limit,
                last_evaluated_key: last_evaluated_key ? JSON.stringify(last_evaluated_key) : null,
            }
        });
        if (!response.data.responses || response.data.responses.length === 0) {
            return { responses: [], lastEvaluatedKey: null };
        }
        const responses: Response[] = response.data.responses;
        return {
            responses: responses,
            lastEvaluatedKey: response.data.last_evaluated_key || null,
        };
    },

    getChallenge(challenge_id: string) {
        return challengeServiceClient.get('/challenge/get-challenge', {
            params: {
                challenge_id
            }
        });
    },

    async getUserChallengeAuth(challenge_id: string, token: string): Promise<Challenge | null> {
        const resp = await challengeServiceClient.get('/challenge/user-challenge', {
            params: {
                challenge_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (resp.data && resp.data.challenge) {
            return resp.data.challenge;
        }
        return null;
    },

    async getUserChallenge(challenge_id: string): Promise<Challenge | null> {
        const resp = await challengeServiceClient.get('/challenge/get-challenge', {
            params: {
                challenge_id
            }
        });
        if (resp.data && resp.data.challenge) {
            return resp.data.challenge;
        }
        return null;
    },

    getUserResponseAuth(response_id: string, token: string) {
        return challengeServiceClient.get('/challenge/user-response', {
            params: {
                response_id,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    },

    getUserResponse(token: string, response_id: string) {
        return challengeServiceClient.get('/challenge/user-response', {
            params: {
                response_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    },

    userCanResponseToChallenge(challenge_id: string) {
        return challengeServiceClient.get('/challenge/user-can-respond-to-challenge', {
            params: {
                challenge_id
            }
        });
    },

    getUserResponsesCount(user_id: string) {
        // Make the GET request using challengeServiceClient
        return challengeServiceClient.get('/challenge/user-responses-count', {
            params: {
                user_id
            }
        });
    },

    updateChallenge(accessToken: string, challenge: Challenge) {
        return challengeServiceClient.post('/challenge/update', challenge, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },

    getPrizePhotoSignedUrl(challenge_id: string, token: string) {
        return challengeServiceClient.get('/challenge/get-signed-url-for-prize-photo', {
            params: {
                challenge_id,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    },

    completeMultipartUpload(object_name: string, upload_id: string, parts: Array<any>) {
        return challengeServiceClient.post('/challenge/complete-multipart-upload', {
            object_name,
            upload_id,
            parts,
        });
    },

    abortMultipartUpload(object_name: string, upload_id: string) {
        return challengeServiceClient.post('/challenge/abort-multipart-upload', {
            object_name,
            upload_id,
        });
    }

};

