<template>
  <v-bottom-navigation color="duelly_black" v-if="viewNavBar()" app class="bottom-navigation">
    <div  class="nav-button" >
      <v-icon id="step1" color="" size="32" @click="navigateTo('/feed')">mdi-home</v-icon>
    </div>

    <!-- Spacer -->
    <div class="nav-button" > </div>

    <div class="nav-button" >
      <img
        id="step2"
        :src="uploadIcon"
        alt="Upload Icon"
        @click="$router.push({ path: '/create-challenge', query: { type: 'challenge' } })"
        :style="{ width: '32px', height: '32px', cursor: 'pointer' }"
      />
      <!-- <v-icon color="" size="32" @click="$router.push({path: '/create-challenge', query: {type: 'challenge'}})">mdi-plus</v-icon> -->
    </div>

    <!-- Spacer -->
    <div class="nav-button" ></div>

    <div class="nav-button" v-if="userProfile.username" >
      <ProfilePhoto 
        id="step3"
        class="mr-2"
        :username="userProfile.username" 
        :profile_photo="userProfile.profile_photo"
        size="small"
        @click="navigateTo('/user-management')"
      />
    </div>
    <div v-else class="nav-button" >
      <a @click="$router.push('/login')">Login</a>
    </div>
  </v-bottom-navigation>
</template>

  
<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/authStore';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import uploadIcon from '@/assets/icons/upload_icon_classy_large.png';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { triggerPushSubscription } from '@/registerServiceWorker';
import  ChallengeService from '@/services/ChallengeService';
import LogService from '@/services/LogService';


const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const userProfile = ref(authStore.getUserProfile());
const swVersion = ref('');


const viewNavBar = () => {    
    userProfile.value = authStore.getUserProfile();
    if (route.path === '/login' || route.path === '/' || route.path.startsWith('/admin')) {
        return false;
    } else {
        return true;
    }
};

async function checkForUpdatesAndReload() {
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) return;
  
  // Force the browser to check for an updated service worker.
  await registration.update();
  
  // Now check if a new SW is waiting.
  if (registration.waiting) {
    console.log("New service worker detected—forcing update.");
    // Send a message to the waiting SW to skip waiting.
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    // Listen for the new SW to take control.
    registration.waiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        console.log("New service worker activated. Reloading page...");
        window.location.reload();
      }
    });
  } else {
    console.log("No waiting service worker found after update check.");
  }
}
  
async function navigateTo(path) {
    console.log("Navigating too:", path);
    // console.log("🏃‍♀️ - swVersio:", swVersion.value);
    
    router.push(path);

    ChallengeService.version()
    .then((version) => {
        console.info("Version response:", version);
        if (version !== swVersion.value) {
            console.info("New version detected. Updating stored version and reloading...");

            window.swVersion = version;
            localStorage.setItem('swVersion', version);
            window.location.reload();
        }
    })
    .catch((error) => {
        console.error("Error fetching version:", error);
    });


    console.log("Notification.permission:", Notification.permission);

    // const logs = await LogService.get()
    // console.log("🚀 - LOGS", logs);

    const userProfile = authStore.getUserProfile(); 
    if (userProfile && userProfile.id) {
      try {
        await LogService.info(`Notification Permission ${Notification.permission}`)
      } catch (error) {
        console.error("Error creating payload:", error);
      }
      await triggerPushSubscription();
    }
    // Force an update check on every navigation.
    checkForUpdatesAndReload();
}
onMounted(async () => {
    const userProfile = authStore.getUserProfile();   
    console.log("userProfile", userProfile);

    swVersion.value = window.swVersion || 'unknown';
});
</script>


<style scoped>
.bottom-navigation {
  width: 100%;
  /* display: flex; */
  justify-content: space-between; 
  align-items: center; /* Center icons vertically */
  border-top: 2px solid rgb(50, 50, 50);
}

.nav-button {
  flex: 1; /* Allow buttons to grow equally */
  display: flex;
  justify-content: center; /* Center icon within button */
  align-items: center; /* Center icon vertically */
}
.nav-button:active {
  opacity: 0.5; 
}
</style>