import { defineStore } from 'pinia';
import WebSocketService, { WebSocketServiceOptions } from '@/services/WebSocketService';
import { useNoticeStore } from './noticeStore';
import { useAuthStore, authStoreEvents } from '@/store/authStore';
import { useChallengeStore } from './challengeStore';
import { useResponseStore } from './responseStore';
import ChallengeService from '@/services/ChallengeService';
import { Challenge } from '@/models/Challenge';
import { Response } from '@/models/Response';


export const useWebSocketStore = defineStore('webSocketStore', {
    state: () => ({
        connected: false,
        deviceId: "",
        visibilityChangeListenerAdded: false,
        wsService: null as WebSocketService | null,
        listenersRegistered: false
    }),

    actions: {
        initialize(accessToken: string) {
            if (WebSocketService.isActive()) {
                console.log('❌ WebSocketService is already initialized.');
                return this.wsService
            }

            if (!this.deviceId) {
                this.deviceId = this.generateDeviceId();
            }

            const options: WebSocketServiceOptions = {
                accessToken,
                environment: 'dev',
                heartbeatInterval: 10000,
                deviceId: this.deviceId,
            };

            this.wsService = WebSocketService.getInstance(options);
            if (!this.wsService) {
                console.error('WebSocketService initialization failed.');
                return;
            }

            // Listen for token refresh events and update WebSocketService
            authStoreEvents.on('tokenRefreshed', (newAccessToken) => {
                if (!this.wsService) {
                    console.error('WebSocketService not initialized.');
                    return;
                }
                this.wsService.updateAccessToken(newAccessToken);
                console.log("WebSocketService accessToken updated on refresh.");
            });

            if (!this.visibilityChangeListenerAdded && this.wsService) {
                this.visibilityChangeListenerAdded = true;

                // Stop ws connections when user navigates away
                // and restart when user comes back
                document.addEventListener('visibilitychange', async () => {
                    const authStore = useAuthStore();
                    if (!this.wsService) {
                        console.error('WebSocketService not initialized.');
                        return;
                    }
                    if (document.hidden) {
                        console.log("Page hidden - closing WebSocket connection.");
                        this.wsService.close();
                    } else {
                        console.log("Page Visible - renablling WebSocket connection.");
                        let accessToken = authStore.getAccessToken()
                        if (!accessToken) {
                            console.info('No access token available.');
                            return;
                        }
                        // Check that authtokens are still valid
                        authStore.showTokenState();
                        if (authStore.isAccessTokenExpired()) {
                            console.log("Token expired - refreshing tokens.");
                            await authStore.refreshTokens();
                            accessToken = authStore.getAccessToken()
                        }

                        if (accessToken) {
                            this.wsService.updateAccessToken(accessToken);
                            this.wsService.connect();
                            this.setupWebSocketListeners(this.wsService as WebSocketService);
                        }
                    }
                });
            }
            // Set up WebSocket event listeners initially
            this.setupWebSocketListeners(this.wsService as WebSocketService);
            this.wsService.connect();
            return this.wsService;
        },
        setupWebSocketListeners(wsService: WebSocketService) {
            if (this.listenersRegistered) {
                console.log("❌ WebSocket listeners already registered.");
                return;
            }
            wsService.on('messageReceived', this.onMessageReceived);
            this.listenersRegistered = true; // Set the flag to true
            console.log("✅ WebSocket listeners registered.");
        },
        async onMessageReceived(data: any): Promise<void> {
            console.log('Store: Received message:', data);
            console.log("event_type:", data.event_type);

            if (data.event_class === 'ws_notice' && data.event_type) {
                const handler = eventHandlers[data.event_type];
                if (handler) {
                    handler(data);
                } else {
                    console.warn('Unhandled event_type:', data.event_type, data);
                }
            } else {
                console.log('Unhandled event_class or missing event_type:', data);
            }
        },

        generateDeviceId(): string {
            return (crypto as any).randomUUID();
        },
        close(): void {
            if (this.wsService) {
                this.wsService.close();
                this.wsService = null;
            }
        }
    },
});

const eventHandlers: Record<string, (data: any) => void> = {
    challenge_modified: (data) => {
        const challengeStore = useChallengeStore();
        challengeStore.addChallenges([data.challenge]);
        console.log('🚀 Saved challenge to store', data.challenge.challenge_id);
    },
    responseModified: (data) => {
        const responseStore = useResponseStore();
        responseStore.add([data.response]);
        console.log('🚀 Saved new Response to Store', data.response.PK);
    },
    new_notification: (data) => {
        console.log("&&& --- new_notification", data)
        const noticeStore = useNoticeStore();
        // for each notificaiton in data.notifications add then to the store
        for (const notification of data.notifications) {
            console.log("Adding notification to store", notification);
            noticeStore.addNotification(notification);
        }


        // noticeStore.addNotification(data.notification);
        // noticeStore.unreadCount = data.unread_count;

        console.log('🚀 NEED TO UPDATE: Handling new notification for user');
    },
};
