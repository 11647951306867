import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-content center" }
const _hoisted_2 = { class: "view-wrapper mt-1" }
const _hoisted_3 = { class: "scroll-item" }

import { ref, onMounted} from 'vue';
import TopicSlider from '../components/TopicSlider.vue';
import VideoPlayer2 from '@/components/VideoPlayer2.vue';
import ScrollItemHeader from '@/components/ScrollItemHeader.vue';
import ScrollItemFooter from '@/components/ScrollItemFooter.vue';
import InfiniteScrollList from '@/components/InfiniteScrollList.vue';
import { useChallengeStore } from '@/store/challengeStore';
import api from '@/services/api';
import { ContentLoader } from 'vue-content-loader'
import { useNoticeStore } from '@/store/noticeStore';
import { useLikeStore } from '@/store/likeStore';
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';
import { useUserProfileStore } from '@/store/userProfileStore';
import ChallengeService from '@/services/ChallengeService';
import NotificationService from '@/services/NotificationService';
import { tourDriver } from '@/tour';

import "driver.js/dist/driver.css";

// Setup Stores

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedView',
  setup(__props) {

const challengeStore = useChallengeStore();
const noticeStore = useNoticeStore();
const likeStore = useLikeStore();
const authStore = useAuthStore();
const webSocketStore = useWebSocketStore();
const userStore = useUserProfileStore();

// const userProfile = authStore.getUserProfile();
// console.log('userProfile', userProfile.id);

const topic = ref("Trending");
const loading = ref(true);

const fetchChallenges = async (lastEvaluatedKey: any) => {
  loading.value = true;

  let response;
  if (topic.value === "Trending") {
    response = await ChallengeService.getTrendingChallenges(50, lastEvaluatedKey);
  } else if (topic.value === "Recents") {
    response = await ChallengeService.getRecentChallenges(50, lastEvaluatedKey);
  } else if (topic.value === "Upcoming") {
    response = await ChallengeService.getUpcomingChallenges(50, lastEvaluatedKey);
  } else {
    response = {
      challenges: [],
      last_evaluated_key: null,
      userProfiles: [],
      user_likes: [],
    };
    // response = await ChallengeService.getChallengesByTopic(topic.value, 10, lastEvaluatedKey);
  }

  loading.value = false;

  // Process response
  if (response.user_likes) {
    likeStore.addUserLikes(response.user_likes);
  }

  if (response.challenges) {
    challengeStore.addChallenges(response.challenges);
  }

  if (response.userProfiles) {
      // itrate over the userProfiles and add them to and them id: profile to the userStore.profiles array
    response.userProfiles.forEach((profile) => {
      userStore.profiles[profile.id] = profile;
    });
  }

  const targetIds = response.challenges.map((challenge: any) => challenge.challenge_id);
  const targetType = "CHALLENGE";
  const subType = "TEMPORARY";

  if (targetIds.length > 0) {
    const subresponse = await ChallengeService.subscribe(targetIds, targetType, subType);
  }

  return {
    items: response.challenges,
    lastEvaluatedKey: response.last_evaluated_key,
  };
};

const submitView = async (challenge_id: string) => {
    await ChallengeService.addView(challenge_id);
}

// Fetch challenges when the topic changes
const fetchChallengesByTopic = (newTopic: string) => {
  topic.value = newTopic;
  return newTopic;
};


onMounted(async () => {
  const accessToken = authStore.getAccessToken();
  if (accessToken) {
      webSocketStore.initialize(accessToken);
  }
  // Fetch users current unread count
  // NotificationService.getUnreadNotificationCount().then((response) => {
  //   noticeStore.unreadCount = response.data.count
  // });

  // NotificationService.getNotifications(1000, null).then((response) => {
  //   noticeStore.notifications = response.data.notifications;
  // });

  const userProfile = authStore.getUserProfile();
  if (userProfile.show_tour === true) {
    setTimeout(() => {
      tourDriver.drive()
    }, 1000);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(TopicSlider, {
        class: "duelly-regular mt-5",
        onTopicSelected: fetchChallengesByTopic
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (loading.value)
        ? (_openBlock(), _createBlock(_unref(ContentLoader), {
            key: 0,
            viewBox: "0 0 250 1100",
            primaryColor: "#1A1A1A",
            secondaryColor: "#2E2E2E"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("circle", {
                cx: "20",
                cy: "20",
                r: "10"
              }, null, -1),
              _createElementVNode("rect", {
                x: "35",
                y: "10",
                rx: "5",
                ry: "15",
                width: "84%",
                height: "20"
              }, null, -1),
              _createElementVNode("rect", {
                x: "5",
                y: "35",
                rx: "5",
                ry: "5",
                width: "96%",
                height: "300"
              }, null, -1),
              _createElementVNode("circle", {
                cx: "20",
                cy: "355",
                r: "10"
              }, null, -1),
              _createElementVNode("rect", {
                x: "35",
                y: "345",
                rx: "5",
                ry: "15",
                width: "84%",
                height: "20"
              }, null, -1),
              _createElementVNode("rect", {
                x: "5",
                y: "370",
                rx: "5",
                ry: "5",
                width: "96%",
                height: "300"
              }, null, -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(InfiniteScrollList, {
        fetchItems: fetchChallenges,
        resetKey: topic.value
      }, {
        default: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ScrollItemHeader, {
              challenge: item,
              route_path: { path: '/challenge-details', query: { challenge_id: item.challenge_id, challenge_user_id: item.user_id } }
            }, null, 8, ["challenge", "route_path"]),
            _createVNode(VideoPlayer2, {
              title: item.title,
              videoSrc: item.video,
              previewImage: item.thumbnail,
              onPlay: ($event: any) => (submitView(item.challenge_id)),
              class: "video-player"
            }, null, 8, ["title", "videoSrc", "previewImage", "onPlay"]),
            _createVNode(ScrollItemFooter, { challenge: item }, null, 8, ["challenge"])
          ])
        ]),
        _: 1
      }, 8, ["resetKey"])
    ])
  ], 64))
}
}

})