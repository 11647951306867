import { userServiceClient } from '@/services/api';
import { UserProfile, UserProfileData } from '@/models/UserProfile';


export default class UserService {
    static federatedLogin(provider: string, token: string) {
        return userServiceClient.post('/user/federated-login', { provider, token });
    }

    static signUp(
        password: string,
        email?: string,
        phone_number?: string
    ) {
        const payload: { email?: string; phoneNumber?: string; password: string } = { password };

        if (email) {
            payload.email = email;
        } else if (phone_number) {
            payload.phoneNumber = phone_number;
        }

        return userServiceClient.post('/user/signup', payload);
    }

    static login(username: string, password: string) {
        return userServiceClient.post('/user/login', { username, password });
    }

    static confirmLogin(user_id: string, confirmation_code: string) {
        return userServiceClient.post('/user/confirm', { user_id, confirmation_code });
    }

    static setProfileDirect(user_id: string, profile: any) {
        return userServiceClient.post(
            '/user/set-profile-direct',
            {
                user_id,
                profile,
            }
        );
    }

    static getSignedUrlsForProfile(image_type: string) {
        return userServiceClient.get('/user/get-signed-urls-for-profile', {
            params: {
                "image_type": image_type,
            }
        });
    }

    static get_admin_profile(user_id: string) {
        return userServiceClient.get('/user/get-admin-profile', {
            params: {
                "id": user_id
            }
        });
    }

    static set_admin_profile(user_id: string, auth: object) {
        return userServiceClient.post(
            '/user/set-admin-profile', {
            "id": user_id,
            "auth": auth
        }
        );
    }

    static profile(access_token: string, user_id: string, public_profile: boolean) {
        return userServiceClient.get('/user/get-profile', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                "id": user_id,
                "public": public_profile,
            }
        });
    }

    static async getPublicProfile(user_id: string): Promise<any> {
        const resp = await userServiceClient.get('/user/get-public-profile', {
            params: {
                "id": user_id
            }
        });
        console.log("getPublicProfile", resp);
        console.log("id", user_id);
        if (!resp || !resp.data) {
            return null;
        }

        return new UserProfile(resp.data)
    }

    static async getPublicProfiles(user_ids: string[]): Promise<any> {
        const resp = await userServiceClient.get('/user/get-public-profiles', {
            params: {
                "user_ids": user_ids.join(','),
            },
        });
        return resp.data.profiles.map((profileData: UserProfileData) => new UserProfile(profileData));
    }

    static refreshToken(refresh_token: string, duration = 60) {
        return userServiceClient.post('/user/refresh-tokens', { "RefreshToken": refresh_token, "Duration": duration });
    }

    static getActiveUsers() {
        return userServiceClient.get('/user/get-active-users');
    }

    static getTotalUsers() {
        return userServiceClient.get('/user/get-total-users');
    }
}

