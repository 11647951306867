import { challengeServiceClient } from '@/services/api';
import { Challenge } from '@/models/Challenge';
import { UserProfile } from '@/models/UserProfile';
import api from '@/services/api';
import axios from 'axios';
import { AxiosResponse } from 'axios';

class ChallengeService {
    static async getChallenge(challenge_id: string): Promise<Challenge> {
        const resp = await challengeServiceClient.get('/challenge/get-challenge', {
            params: {
                challenge_id,
            },
        });
        return resp.data.challenge;
    }

    static async getCountByUserId(user_id: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/user-challenges-count', {
            params: {
                user_id
            }
        });
        return resp.data.count;
    }

    static async getUserJudgedChallengesCount(user_id: string, token: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/user-judged-challenge-count', {
            params: {
                user_id,
            },
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return resp.data.count;
    }

    static getUserChallengesCount(user_id: string) {
        // Make the GET request using challengeServiceClient
        return challengeServiceClient.get('/challenge/user-challenges-count', {
            params: {
                user_id
            }
        });
    }

    static async getUserChallengeAuth(challenge_id: string, token: string): Promise<Challenge | null> {
        const resp = await challengeServiceClient.get('/challenge/user-challenge', {
            params: {
                challenge_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (resp.data && resp.data.challenge) {
            return resp.data.challenge;
        }
        return null;
    }

    static getPrizePhotoSignedUrl(challenge_id: string, token: string, challenge_field = "prize_photo") {
        return challengeServiceClient.get('/challenge/get-signed-url-for-prize-photo', {
            params: {
                challenge_id,
                challenge_field,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async updateChallenge(accessToken: string, challenge: Challenge): Promise<boolean> {
        console.log("updateChallenge challenge", challenge);
        try {
            const resp = await challengeServiceClient.post('/challenge/update', challenge, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // const resp = await api.updateChallenge(accessToken, this);
            if (resp.status === 200 && resp.data.challenge) {
                Object.assign(this, resp.data.challenge);
                return true;
            } else {
                console.error('Failed to update challenge:', resp.data.message);
                return false;
            }
        } catch (error) {
            console.error('Error updating challenge:', error);
            return false;
        }
    }

    static async getRecentChallenges(
        limit: number,
        last_evaluated_key: any = null,
    ): Promise<{ challenges: Challenge[], last_evaluated_key: any, userProfiles: UserProfile[], user_likes: string[] }> {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const resp = await challengeServiceClient.get('/challenge/get-recent', {
            params: {
                limit,
                last_evaluated_key: serializedKey,
            },
        });
        if (!resp.data.challenges || resp.data.challenges.length === 0) {
            return { challenges: [], last_evaluated_key: null, userProfiles: [], user_likes: [] };
        }
        return {
            challenges: resp.data.challenges,
            userProfiles: resp.data.user_profiles,
            last_evaluated_key: resp.data.last_evaluated_key || null,
            user_likes: resp.data.user_likes || [],
        };
    }

    static async getUpcomingChallenges(
        limit: number,
        last_evaluated_key: any = null,
    ): Promise<{ challenges: Challenge[], last_evaluated_key: any, userProfiles: UserProfile[], user_likes: string[] }> {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const resp = await challengeServiceClient.get('/challenge/get-upcoming', {
            params: {
                limit,
                last_evaluated_key: serializedKey,
            },
        });
        if (!resp.data.challenges || resp.data.challenges.length === 0) {
            return { challenges: [], last_evaluated_key: null, userProfiles: [], user_likes: [] };
        }
        return {
            challenges: resp.data.challenges,
            last_evaluated_key: resp.data.last_evaluated_key || null,
            userProfiles: [],
            user_likes: resp.data.user_likes || [],
        };
    }

    static async getTrendingChallenges(limit: number, last_evaluated_key: any = null) {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const trendingResponse = await challengeServiceClient.get('/challenge/get-trending', {
            params: {
                limit,
                last_evaluated_key: serializedKey,
            }
        });
        return {
            challenges: trendingResponse.data.challenges,
            userProfiles: [],
            last_evaluated_key: trendingResponse.data.last_evaluated_key || null,
            user_likes: trendingResponse.data.user_likes || [],
        };
    }

    static async addView(challenge_id: string, parent_type = 'CHALLENGE') {
        try {
            const resp = await challengeServiceClient.post('/challenge/add-view', {
                parent_id: challenge_id,
                parent_type: parent_type
            });
            if (!resp) {
                console.error('Failed to add view to challenge');
                return false;
            }

            return resp.data.response;
        } catch (error) {
            console.error('Error adding view:', error);
            return false;
        }
    }

    static async getJudgesForChallenge(challenge_id: string, limit: number, last_evaluated_key: any = null) {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const response = await challengeServiceClient.get('/challenge/get-judges-for-challenge', {
            params: {
                challenge_id,
                limit,
                last_evaluated_key: serializedKey,
            }
        });
        console.log("getJudgesForChallenge response", response.data);
        return {
            judges: response.data.judges,
            user_profiles: response.data.profiles,
            last_evaluated_key: response.data.last_evaluated_key || null,
        };
    }

    static async isSubscribed(
        target_id: string,
        target_type: string,
    ): Promise<{ PERMANENT: boolean, TEMPORARY: boolean }> {
        try {
            const resp = await challengeServiceClient.get('/challenge/is-subscribed', {
                params: {
                    target_id,
                    target_type,
                },
            });
            console.log("isSubscribed resp.data", resp.data);
            return resp.data;
        } catch (error) {
            console.error('Error checking subscription:', error);
            return { "PERMANENT": false, "TEMPORARY": false };
        }
    }

    static async subscribe(
        target_ids: string[],
        target_type: string,
        sub_type: string,
    ): Promise<boolean> {
        try {
            const resp = await challengeServiceClient.post('/challenge/subscribe', {
                target_ids,
                target_type,
                sub_type,
            });
            console.log("subscribe resp", resp);
            // const resp = await api.updateChallenge(accessToken, this);
            if (resp.status === 200 && resp.data.message === "Subscription added successfully.") {
                return true;
            } else {
                console.error('Failed to subscribe:', resp.data.error);
                return false;
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            return false;
        }
    }

    static async unsubscribe(
        target_id: string,
        target_type: string,
        sub_type: string,
    ): Promise<boolean> {
        try {
            const resp = await challengeServiceClient.post('/challenge/unsubscribe', {
                target_id,
                target_type,
                sub_type,
            });
            console.log("unsubscribe resp", resp);
            // const resp = await api.updateChallenge(accessToken, this);
            if (resp.status === 200 && resp.data.message === "Subscription removed successfully.") {
                return true;
            } else {
                console.error('Failed to Unsubscribe:', resp.data.error);
                return false;
            }
        } catch (error) {
            console.error('Error Unsubscribing:', error);
            return false;
        }
    }

    static async feedback(
        form_data: object,
    ): Promise<boolean> {
        console.log("feedback form_data", form_data);
        try {
            const resp = await challengeServiceClient.post(
                '/challenge/feedback',
                form_data,
            );
            console.log("feedback resp", resp);
            if (resp.status === 200 && resp.data.message === "Feedback successfully.") {
                return true;
            } else {
                console.error('Feedback Failed:', resp.data.error);
                return false;
            }
        } catch (error) {
            console.error('Error Leaving Feedback:', error);
            return false;
        }
    }

    static async version(): Promise<string> {
        try {
            const resp = await challengeServiceClient.get(
                '/challenge/get-version',
            );
            console.log("feedback resp", resp);
            if (resp.status === 200 && resp.data.version) {
                return resp.data.version;
            } else {
                throw new Error('Failed to get version');
            }
        } catch (error) {
            throw new Error('Failed to get version');
        }
    }
}

export default ChallengeService;
