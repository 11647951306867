import { Workbox } from 'workbox-window';
import NotificationService from './services/NotificationService';
import LogService from '@/services/LogService';

// VAPID public key from your backend environment variable
const VAPID_PUBLIC_KEY = process.env.VUE_APP_VAPID_PUBLIC_KEY;


function isIos(): boolean {
  // console.log("userAgent", navigator.userAgent);
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !((window as any).MSStream);
}


/**
 * Converts a base64 string to a Uint8Array (needed for the push subscription)
 */
function urlBase64ToUint8Array(base64String: any) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * Requests Notification permission from the user.
 */
async function requestNotificationPermission() {
  if (!('Notification' in window)) {
    console.warn('This browser does not support notifications');
    return false;
  }
  if (Notification.permission === 'granted') {
    return true;
  }
  if (Notification.permission === 'denied') {
    console.warn('Notification permission has been denied');
    return false;
  }
  const permission = await Notification.requestPermission();
  return permission === 'granted';
}

/**
 * Subscribes the user to push notifications using the provided service worker registration.
 */
async function subscribeToPushNotifications(registration: any) {
  if (!('pushManager' in registration)) {
    console.warn('Push notifications are not supported on this platform.');
    return null;
  }
  let subscription;
  try {
    subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      console.log('Already subscribed to push notifications.');
    } else {
      if (!VAPID_PUBLIC_KEY) {
        LogService.error('VAPID_PUBLIC_KEY is not set.');
        return null;
      }
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      });
      LogService.info('New push subscription created.');
    }
    // Send the subscription to your backend
    LogService.info("subscribing to push notifications");
    const response = await NotificationService.pushSubscribe(subscription);
    if (!response) {
      LogService.error('Failed to register subscription with backend.');
      throw new Error('Failed to register subscription with backend.');
    }
    LogService.info('Push subscription registered with backend.');
    return subscription;
  } catch (error) {
    LogService.error(`Error subscribing to push notifications: ${error}`);
    if (subscription) {
      try {
        const unsubscribed = await subscription.unsubscribe();
        LogService.info('Unsubscribed from push notifications due to error.');
        console.log('Unsubscribed from push notifications due to error.', unsubscribed);
      } catch (unsubscribeError) {
        LogService.error(`Error during unsubscription: ${unsubscribeError}`);
      }
    }
  }
}

/**
 * Displays an update notification banner.
 */
function showUpdateNotification(onUpdate: any) {
  const banner = document.createElement('div');
  banner.id = 'update-banner';
  banner.style.position = 'fixed';
  banner.style.bottom = '0';
  banner.style.left = '0';
  banner.style.width = '100%';
  banner.style.backgroundColor = '#fff3cd';
  banner.style.color = '#856404';
  banner.style.padding = '15px';
  banner.style.textAlign = 'center';
  banner.style.boxShadow = '0 -2px 5px rgba(0,0,0,0.3)';
  banner.style.zIndex = '9999';
  banner.innerHTML = `
    <span>New version available.</span>
    <button id="update-btn" style="margin-left: 15px; padding: 5px 10px;">Update</button>
  `;
  document.body.appendChild(banner);
  document.getElementById('update-btn')?.addEventListener('click', () => {
    onUpdate();
  });
}

// Register the service worker if supported.
if ('serviceWorker' in navigator) {
  console.log('Service Worker is supported');
  const storedVersion = localStorage.getItem('swVersion') || 'undefined';
  window.swVersion = storedVersion;
  // window.swVersion = 'v=6';// Update this on each deploy

  const wb = new Workbox(`/service-worker.js?${window.swVersion}`);
  console.log('Service Worker version:', window.swVersion);

  // Listen for the "waiting" event, which indicates a new SW is ready.
  wb.addEventListener('waiting', () => {
    console.log("New service worker waiting. Automatically skipping waiting and updating...");
    wb.messageSkipWaiting();
    wb.addEventListener('controlling', () => {
      console.log("New service worker controlling. Reloading page...");
      setTimeout(() => {
        console.log("REALOADING PAGE");
        window.location.reload();
      }, 1000);
    });
  });

  wb.register()
    .then((registration) => {
      if (!registration) {
        throw new Error('Service Worker registration failed.');
      }
      console.log('Service Worker registered with scope:', registration.scope);
      (window as any).swRegistration = registration;
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}


export async function triggerPushSubscription() {
  // If swRegistration is not yet set, wait for the service worker to be ready.
  let registration = (window as any).swRegistration;
  if (!registration) {
    await LogService.info(`awaiting navigator.serviceWorker.ready`);
    registration = await navigator.serviceWorker.ready;
    await LogService.info(`navigator.serviceWorker.ready finished with registration: ${registration}`);
    (window as any).swRegistration = registration; // Cache for future use.
  }

  // Guard: check if a push subscription already exists.
  const existingSubscription = await registration.pushManager.getSubscription();
  if (existingSubscription) {
    await LogService.info(`Push subscription already exists, skipping subscription.`);
    return;
  }

  if (registration) {
    requestNotificationPermission().then(async (granted) => {
      if (granted) {
        await LogService.info(`await subscribeToPushNotifications:`);
        subscribeToPushNotifications(registration);
        await LogService.info(`subscribeToPushNotifications completed`);
      } else {
        await LogService.error(`Permission not granted: ${granted}`);
      }
    });
  } else {
    await LogService.error(`No registration: ${registration},`);
    console.error('No service worker registration available for push subscription.');
  }
}
