<template>
    <div class="challenge-header" @click="handleClick">
        <ProfilePhoto 
          class="mr-2"
          :username="userProfile.username" 
          :profile_photo="userProfile.profile_photo" 
          @click.stop="router.push({ path: '/public-profile', query: { user_id: userProfile.id } })"
        />

        <div class="challenge-details"  @click="handleClick">
            <h2 class="title"> {{ challenge.title }}</h2>
            <span class="subheading duelly-fog" @click.stop="router.push({ path: '/public-profile', query: { user_id: userProfile.id } })"><b>By: </b> 
              <span class="subhead duelly-aqua"> {{  userProfile.username }}</span>
            </span>
            <span class="subheading duelly-fog">
              <span 
                v-html="statusMsg"
                class="status subhead" 
                :class="{
                  'text-info': challenge.status===ChallengeStatus.PUBLISHED,
                  'text-success': challenge.status===ChallengeStatus.ACTIVE,
                  'text-warning': challenge.status===ChallengeStatus.JUDGING,
                  'text-error': challenge.status===ChallengeStatus.COMPLETED
                }"
                ></span>
            </span>
        </div>
        
        <div 
          class="challenge-actions column-container"
        >
          <v-btn 
            v-if="challenge.status === ChallengeStatus.COMPLETED" 
            @click="handleClick" 
            color="primary"
            class="enter-challenge"
          >
            Results
          </v-btn>
          <v-btn 
            v-else-if="challenge.status == ChallengeStatus.JUDGING" 
            @click="handleClick"
            color="primary"
            class="enter-challenge"
          >
            Judge
          </v-btn>
          <v-btn 
          v-else
            @click="handleClick" 
            color="primary"
            class="enter-challenge"
          >
            Enter
          </v-btn>
      </div>
    </div>
</template>

<style scoped>
.challenge-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  gap: 20px;
}

.challenge-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.subhead {
  font-size: 12px;
}
.challenge-actions {
  justify-content: flex-end;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 10px;
}
.enter-challenge {
  padding: 0 8px;
  min-width: auto;
  font-weight: normal;
  box-shadow: none;
  text-transform: none;
  border: 1px solid var(--v-theme-secondary);
  size: 14px;
}
.title {
  font-size: 14px;
}
</style>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useUserProfileStore } from '@/store/userProfileStore';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { useChallengeStore } from '@/store/challengeStore';
import { calculateTimeRemaining } from '@/utils/challengeUtils';
import { Challenge, ChallengeStatus } from '@/models/Challenge';

const router = useRouter();
const store = useUserProfileStore();
const challengeStore = useChallengeStore();

const props = defineProps({
  challenge: Object,
  route_path: Object,
});

let timer = null;


const challenge = computed(() => {
    const challenge = challengeStore.getChallenge(props.challenge.challenge_id);
    if (!challenge) {
        console.log("Challenge is not available in the store yet.");
        return null;
    }
    return challenge
});

const userProfile = ref({});
const statusMsg = ref('');

const handleClick = () => {
  if (props.route_path) {
    router.push(props.route_path);
  }
};

const getProfile = async (user_id) => {
  userProfile.value = await store.getPublicProfile(user_id);
  return userProfile;
};

getProfile(props.challenge.user_id);

function statusMessage() {
    const resp = calculateTimeRemaining(challenge.value);  
    challengeStore.updateChallenge(challenge.value.PK, {status: resp.status });
    statusMsg.value = resp.fromMessage;
}

onMounted(() => {
    statusMessage();
    timer = setInterval(statusMessage, 1000); // Update every second
});
onBeforeUnmount(() => {
  clearInterval(timer);
});
</script>