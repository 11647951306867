import { defineStore } from 'pinia';
import { Notification, LastEvaluatedKey } from '@/interfaces';
import NotificationService from '@/services/NotificationService';
import { useAuthStore } from './authStore';

interface NoticeStoreState {
    notifications: Notification[];
    lastEvaluatedKey: LastEvaluatedKey | null;
}


export const useNoticeStore = defineStore('noticeStore', {
    state: (): NoticeStoreState => ({
        notifications: [],
        lastEvaluatedKey: null,
    }),
    getters: {
        unreadCount: (state) => {
            if (!state.notifications) {
                console.error("No notifications available.");
                return 0;
            }
            console.log("unreadCount-state.notificaitons", state.notifications)
            const unreadCount = state.notifications.filter(
                (n) => !n.status || n.status.startsWith('unread')
            ).length;
            // const unreadCount = state.notifications.filter((n) => n.status.startsWith('unread')).length
            console.log("unreadCount YOU ARE HERE", unreadCount)
            return unreadCount;

            // return state.notifications.filter((n) => n.status.startsWith('unread')).length
        },

        getNotices(state): Notification[] {
            console.log("getNotices YOU ARE HERE")
            return this.notifications;
        },

        getUnread(state): Notification[] {
            console.log("getUnread YOU ARE HERE");
            const unread: Notification[] = [];
            for (let i = 0; i < state.notifications.length; i++) {
                const item = state.notifications[i];
                // Skip if item is falsy or doesn't have a valid status string
                if (!item || typeof item.status !== 'string') {
                    continue;
                }
                try {
                    if (item.status.startsWith('unread')) {
                        unread.push(item);
                    }
                } catch (error) {
                    console.error("Error processing notification:", item, error);
                    // Skip the problematic item
                    continue;
                }
            }
            return unread;
        },
        getRead(state): Notification[] {
            const read: Notification[] = [];
            for (let i = 0; i < state.notifications.length; i++) {
                const item = state.notifications[i];
                if (!item || typeof item.status !== 'string') {
                    continue;
                }
                try {
                    if (item.status.startsWith('read')) {
                        read.push(item);
                    }
                } catch (error) {
                    console.error("Error processing notification:", item, error);
                    continue;
                }
            }
            return read;
        }

    },
    actions: {
        async markAsRead(notification: any) {
            await NotificationService.markAsRead([{ ...notification }]).then((response) => {
                console.log("Response from markAsRead", response);
                notification.status = "read";
            });
        },
        async fetchNotifications(
            lastEvaluatedKey: LastEvaluatedKey | null = null,
            limit = 25
        ): Promise<{ items: Notification[]; lastEvaluatedKey: LastEvaluatedKey | null } | void> {
            const accessToken = useAuthStore().getAccessToken();
            if (!accessToken) {
                console.error('No access token available.');
                return;
            }
            const response = await NotificationService.getNotifications(limit, lastEvaluatedKey);
            console.log("* fetchNotifications - response.data.notifications", response.data.notifications)
            this.notifications.splice(0, this.notifications.length, ...response.data.notifications);
            this.lastEvaluatedKey = response.data.lastEvaluatedKey;

            console.log("response.data.notifications", response.data.notifications);
            return {
                items: response.data.notifications,
                lastEvaluatedKey: response.data.lastEvaluatedKey,
            };
        },

        addNotification(notification: Notification) {
            if (!notification) {
                console.error("No notification provided.");
                return;
            }
            console.log("Adding notification to store", notification);

            // Optional: minimal validation—if there's no PK or SK, skip.
            if (!notification?.PK || !notification?.SK) {
                console.error("Invalid notification—missing PK or SK. Skipping add.");
                console.error(notification);
                return;
            }

            // Find an existing notification with the same PK and SK
            const index = this.notifications.findIndex(
                (n) => n.PK === notification.PK && n.SK === notification.SK
            );

            if (index !== -1) {
                // If found, overwrite it
                this.notifications.splice(index, 1, notification);
                console.log(`Overwrote notification at index ${index}`);
            } else {
                // Otherwise, add it to the front
                this.notifications.unshift(notification);
                console.log("Inserted new notification at the front");
            }

            console.log("After addNotification:", this.notifications);
        },


        // addNotification(notification: Notification) {
        //     console.log("Adding notification to store", notification);
        //     const index = this.notifications.findIndex(
        //         (n) => n.PK === notification.PK && n.SK === notification.SK
        //     );
        //     if (index !== -1) {
        //         // Overwrite the existing notification
        //         this.notifications.splice(index, 1, notification);
        //     } else {
        //         // Unshift so the newest appear first.
        //         this.notifications.unshift(notification);
        //     }
        // },

        async removeNotification(item: any) {
            if (!item || !item.PK || !item.SK) {
                console.error("Invalid notification object for deletion:", item);
                return;
            }

            console.log("Attempting to remove notification:", item);

            try {
                // Call server delete
                await NotificationService.deleteNotifications([item]);

                // Log current notifications before removal
                console.log("Current notifications before removal:", this.notifications);

                // Loop backwards through the array to safely splice out matching items
                for (let i = this.notifications.length - 1; i >= 0; i--) {
                    const n = this.notifications[i];
                    if (n && n.PK === item.PK && n.SK === item.SK) {
                        console.log("Removing notification at index", i, n);
                        this.notifications.splice(i, 1);
                    }
                }

                // Reassign a new array reference to ensure reactivity and remove any undefined entries
                this.notifications = this.notifications.filter(n => n !== undefined);
                console.log("Notifications after removal:", this.notifications);
            } catch (error) {
                console.error("Error deleting notification from server:", error);
            }
        },


        // addNotification(notification: Notification) {
        //     // Unshift so the newest appear first.
        //     console.log("Adding notification to store", notification);
        //     this.notifications.unshift(notification);
        // },

        // THIS IS EXAMPLE CODE ONLY THAT UPDATED FE OBJECT AND NOT DYNAMO
        // markNotificationAsRead(notificationId: string) {
        //     const index = this.notifications.findIndex((n) => n.id === notificationId);
        //     if (index !== -1 && this.notifications[index].SK.startsWith('#unread')) {
        //         // Update the SK flag from unread to read.
        //         this.notifications[index].SK = this.notifications[index].SK.replace('#unread', '#read');
        //     }
        // },
        // Remove a notification from the store
        // removeNotification(notificationId: string) {
        //     this.notifications = this.notifications.filter((n) => n.id !== notificationId);
        // },
        // getUnread(): Notification[] {
        //     return this.notifications.filter((item) => item.SK.startsWith('#unread'));
        // },
        // getRead(): Notification[] {
        //     return this.notifications.filter((item) => item.SK.startsWith('#read'));
        // },
    },
});
