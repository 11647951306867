import { adminServiceClient } from '@/services/api';
import { Challenge } from '@/models/Challenge';
import { UserProfile } from '@/models/UserProfile';
import api from '@/services/api';
import axios from 'axios';
import { AxiosResponse } from 'axios';

export default class AdminService {
    static async isAdminUser(): Promise<boolean> {
        try {
            const resp = await adminServiceClient.post('/admin/is-admin-user');

            // const resp = await api.updateChallenge(accessToken, this);
            if (resp.status === 200 && resp.data.is_admin) {
                return resp.data.is_admin;
            } else {
                console.error('Failed to check admin user status returnin false');
                return false;
            }
        } catch (error) {
            console.error('Error checking admin status:', error);
            return false;
        }
    }
}

