<!--
 * InfiniteScrollList.vue
 *
 * Description:
 *   A generic infinite scrolling list component that supports two modes of data sourcing:
 *     1. Reactive items via a provided `items` prop.
 *     2. On-demand data fetching via a `fetchItems` function prop.
 *
 * Features:
 *   - Accepts an optional `items` prop for initializing the list with a reactive array.
 *   - Accepts an optional `fetchItems` prop function for fetching paginated items.
 *   - Requires a `resetKey` prop that, when changed, resets the internal list and triggers a new fetch.
 *   - Automatically fetches initial items on mount if a fetch function is provided.
 *   - Uses an Intersection Observer (via @vueuse/core) on the last rendered item to automatically load more items when visible.
 *   - Exposes an `addItem` method to allow external components to add a new item to the top of the list.
 *   - Emits an `item-clicked` event when an item is clicked, allowing parent components to handle click events.
 *
 * Usage:
 *   <InfiniteScrollList 
 *       :fetchItems="fetchMyItems" 
 *       :resetKey="myResetKey" 
 *       :items="preloadedItems"
 *   >
 *       <template #default="{ item }">
 *           <div>{{ item.text }}</div>
 *       </template>
 *   </InfiniteScrollList>
 *
 * Notes:
 *   - If both `items` and `fetchItems` are provided, the component initializes with `items`
 *     but will use `fetchItems` to load more items when needed.
 *   - The component automatically handles loading state and pagination based on the provided fetch function.
-->

<script setup>
import { ref, watch } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  fetchItems: {
    type: Function,
    required: false,
  },
  resetKey: {
    type: Number,
    required: true,
  },
  items: {
    type: Array,
    required: false
  },
});

const items = ref(props.items ? props.items : []); // Initialize with provided items or empty array
const loading = ref(false);
const lastEvaluatedKey = ref(null);
const lastItemRef = ref(null);

// Set up the observer for the last item to trigger more fetches
const setLastItemRef = (index) => {
  return (el) => {
    if (index === items.value.length - 1) {
      lastItemRef.value = el;
    }
  };
};

// exposed methods
const addItem = (newItem) => {
  items.value.unshift(newItem); // Add the new comment at the top
};
defineExpose({ addItem });


// Fetch items with the provided fetch function
const fetchItems = async () => {
  if (loading.value) return;

  loading.value = true;
  if (props.items) {
    items.value = props.items;
  }
  // If no fetch function is provided, return early
  if (!props.fetchItems) {
    loading.value = false;
    return;
  }

  try {
    const response = await props.fetchItems(lastEvaluatedKey.value);
    items.value.push(...response.items);
    lastEvaluatedKey.value = response.lastEvaluatedKey;
  } catch (error) {
    console.error('Error fetching items:', error);
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.resetKey,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      items.value = [];
      lastEvaluatedKey.value = null;
      fetchItems(); // Fetch new items based on the new topic
    }
  }
);

// Watch for intersection of the last item and load more items when visible
watch(lastItemRef, (newLastItem) => {
  if (newLastItem) {
    useIntersectionObserver(lastItemRef, ([{ isIntersecting }]) => {
      if (isIntersecting && !loading.value && lastEvaluatedKey.value) {
        fetchItems();
      }
    });
  }
});

// Fetch initial items on mount
fetchItems();
</script>

<template>
  <div class="view-wrapper">
    <!-- Iterate through items and emit a click event when an item is clicked -->
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="view-content"
      :ref="setLastItemRef(index)"
      @click="$emit('item-clicked', item)"
    >
      <!-- Slot allows the parent to define how to render each item -->
      <slot :item="item"></slot>
    </div>

    <!-- Display a Vuetify spinner when fetching more items -->
    <div v-if="loading" class="d-flex justify-center mt-4">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>
